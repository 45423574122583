body {
  font-family: 'Asap Bold', arial;
}
  
.margin-top{
  margin-top: 20px;
}

.border-60{
  border-radius: 60px
}
p {
  color: #464646;
}

/* lista */
li {
  list-style-type: none;
}
li a:hover {
    color: #3f89c6 !important;
    cursor: pointer
}

/* Progress bar point*/
span.points {
  background: #58a3fb;
  width: 11.92px; 
  height: 6.22px;
  display: inline-block;
  font-weight: bold;
  line-height: 0.5;
  margin-right: 5px;
}
span.grey-points {
  background: #cfcfcf;
  width: 11.92px; 
  height: 6.22px;
  display: inline-block;
  font-weight: bold;
  line-height: 0.5;
  margin-right: 5px;
}

pre {
  white-space: pre-wrap;       /* css-3 */
  white-space: -moz-pre-wrap;  /* Mozilla, since 1999 */
  white-space: -pre-wrap;      /* Opera 4-6 */
  white-space: -o-pre-wrap;    /* Opera 7 */
  word-wrap: break-word;       /* Internet Explorer 5.5+ */

  font-size:11px;
  font-family: 'Asap Bold', arial;
  margin: 1em 0;
}

.shortInformation{
  display: block;
  display: -webkit-box;
  line-height: 1.4;
  -webkit-line-clamp: 9;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: justify;
  margin-left: 6px;
  margin-right: 6px;
  margin-top: 6px;
}

.capitalLetter
{
    float:left;
    color:#58a3fb;
    font-size:37px;
    line-height:1px;
    padding:3px;
    font-family: 'Asap Bold', arial;
    margin-right: 5px;
    margin-top: 5px;
    margin-bottom: 5px;
}
.letterBlue
{
    color:#58a3fb;
    font-family: 'Asap Bold', arial;
    margin-right: 2px;
}

.leaflet-container {
  background: white !important;
}
.interlineado {
  line-height: 1;
}
.interlineadoSmall {
  line-height: 0.8;
}


.buttonSimple  {
  background: none;
	color: inherit;
  border: none;
  cursor: all-scroll;
}
.buttonSimple:focus { 
  outline: none;
  cursor: all-scroll;
}
.size-25{
  font-size:25px;
}
.size-19{
  font-size:19px;
}
.size-16{
  font-size:16px;
}
.size-15{
  font-size:15px;
}
.size-14{
  font-size:14px;
}
.size-13{
  font-size:13px;
}
.size-12{
  font-size:12px;
}
.size-11{
  font-size:11px;
}
.size-10{
  font-size:10px;
}
.size-9{
  font-size:9px;
}
/* modal popup*/
.modal-body-popup{
  height: 70vh;
  overflow-y: auto;
}
/* end modal popup*/

.borderRight {
  border-right: 1px solid rgba(0,0,0,.15);
}
.borderBottom {
  border-bottom: 1px solid rgba(0,0,0,.15);
}

/*Button izquierda y derecha*/
button.detail {
    position: absolute !important;
    left: -190px; /* modifica mas a la derecha o a la izquiera el boton volver al mapa*/
    right: auto;
    border: 0 none;
    background-color: #509afb; /*modifica el color del boton*/
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: 36px;
    border-bottom-right-radius: 36px;
    line-height: 20px;
    height: 20px; /* ancho del boton*/
}
.detail {
    height:25px; /*modifica lo ancho del boton */
    width:350px; /* modifica lo largo del boton*/
    position: absolute !important;
    left:-210px;top:210px; /*modifica la ubicacion de alto y bajo*/
    transform:rotate(7deg); /*no se para que es*/
    -ms-transform:rotate(270deg); /* IE 9 */
    -moz-transform:rotate(270deg); /* Firefox */
    -webkit-transform:rotate(270deg); /* Safari and Chrome */
    -o-transform:rotate(270deg); /* Opera */
}
button.detail.btn-primary:active {
    background-color: #3e86d9;
}
button.detail.btn-primary:focus,
button.detail.btn-primary:active:focus {
    outline: 0 none;
    box-shadow: none;
}
button.detail:before {
    content: '▼';
    width: 50px; /*bolita del button*/
    height: 20px; /*bolita del button*/
    position: absolute !important;
    top: auto;
    bottom: -20px;
    left: 48%;
    background-color: inherit;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: 70px;
    border-bottom-right-radius: 70px;
    line-height: 14px;
    font-size: 0.9em;
}
button.detail.left:before {
    content: '▲';
    top: -20px;
    bottom: auto;
    border-top-left-radius: 70px;
    border-top-right-radius: 70px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    line-height: 20px;
}
button.detail.left {
    right: -165px; /* ubica el boton mas a la derecha o a la izquierda el boton ver detalle*/
    left: auto;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-top-left-radius: 36px;
    border-top-right-radius: 36px;
}
/*Fin Button izquierda y derecha*/